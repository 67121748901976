<template>
 <div >
      <div class="container-fluid">
                <div class="pre-loader text-center">
                    <span class="loader"></span>
                </div>
                   </div>
                      </div>
</template>

<script>
import { mapState } from "vuex";
export default {



methods:{

    getTransferParam(){
       // this.$store.dispatch("auth/logoutUser");


if(this.token !== null ){

        this.$store.commit("auth/saveTransferDetails", this.$route.query);
           this.$store.dispatch("auth/sendTransferDetails", this.$route.query);
           this.$store.commit("auth/hideTransferActiveStatus");
}

else if(this.$route.query.iu == 1){
    this.$store.commit("auth/saveTransferDetails", this.$route.query);
     this.$router.push('/login')

}
else{
    this.$store.commit("auth/saveTransferDetails", this.$route.query);
     this.$router.push('/signup')

}
    }
},

  computed: {
    ...mapState({

  token: state => state.auth.token,

        
  })
  },
mounted(){

this.getTransferParam()

}
  }
</script>

<style>

</style>